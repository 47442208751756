import '../../Styles/Dice.css';

interface DiceProps {
  diceFace: number;
}

const Dice: React.FC<DiceProps> = ({ diceFace }) => {
  const renderDots = (count: number) => (
    <div className="dot-container">
      {[...Array(count)].map((_, index) => (
        <div key={index} className="dot"></div>
      ))}
    </div>
  );

  return (
    <div>
      <div className="dice-container">
        <div className={`dice face-${diceFace}`}>
          {[1, 2, 3, 4, 5, 6].map((face) => (
            <div key={face} className={`face-${face}`}>
              {renderDots(face)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dice;

