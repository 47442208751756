import { Link } from "react-router-dom";
import Win from "..//assets/icons8-confetti-80.png";
import Lose from "..//assets/icons8-sad-80.png";
import "../Styles/PopUp.css";
const { REACT_APP_CONTACT_URL } = process.env;

interface ModalProps {
  state: boolean;
}

export default function Modal({ state }: ModalProps) {
  return (
    <div className="popup">
      <div className="modal ">
        <div className="overlay"></div>
        <div className="modal-content flex flex-col items-center justify-center gap-y-4 w-72 md:w-80 h-96 px-8">
          <div>
            <img
              className=" h-24 md:h-28"
              src={state ? Win : Lose}
              alt="emoji"
            />
          </div>
          {state ? (
            <h1 className="text-2xl font-semibold">
              Congratulations, you guessed right!
            </h1>
          ) : (
            <h1 className="text-2xl font-semibold">Sorry, wrong guess!</h1>
          )}
          <Link to={"/"}>
            {" "}
            <button className="button rounded-full uppercase">Try again</button>
          </Link>
          <a
            href={REACT_APP_CONTACT_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="button rounded-full uppercase">
              {" "}
              Contact Us
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
