import { useState } from "react";
import "../../Styles/DiceInput.css";

interface DiceInputProps {
  onRoll: () => void;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  disable: boolean;
}

const DiceInput: React.FC<DiceInputProps> = ({
  onRoll,
  inputValue,
  setInputValue,
  disable,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const handleInputChange = (e: any) => {
    const value: any = e.target.value;
    // Check if the input is a number
    if (isNaN(value)) {
      setErrorMessage("Please enter a valid number.");
    } else {
      const numericValue = parseInt(value, 10);
      // Check if the number is within the valid range
      if (numericValue < 2 || numericValue > 12) {
        setErrorMessage("Please enter a number between 2 and 12.");
      } else {
        setErrorMessage("");
      }
    }

    setInputValue(value);
  };

  return (
    <div className="container"> 
          <div className="">
          <input
            type="number"
            className="shadow focus:outline-none text-l md:text-2xl w-48 md:w-80 pl-4 md:pl-6 font-semibold h-12 md:h-16   rounded-l-full "
            placeholder="Guess the number"
            value={inputValue}
            onChange={handleInputChange}
            disabled={disable}
          />
          <button
            
            className="shadow  cursor-pointer  text-l md:text-2xl text-white rounded-r-full  h-12 md:h-16  px-6 md:px-8 font-semibold  uppercase bg-blue-400 "
            disabled={errorMessage !== "" || !inputValue.trim() || disable}
            onClick={onRoll}
          >
            Roll
          </button>
          </div>
    
      {errorMessage && (
        <div className=" text-sm md:text-xl font-semibold text-red-600 pt-4 px-10">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default DiceInput;
