import { useState } from "react";
import PopUp from "../../Components/PopUp";
import Dice from "./Dice";
import logo from "../../assets/workplay-logo-with-shadow.png";
import DiceInput from "./DiceInput";
const { REACT_APP_LOGO_URL } = process.env;

interface GameProps {}

const Game: React.FC<GameProps> = ({}) => {
  const faces = 6;
  let intrvl: any;
  const [diceFaces, setDiceFaces] = useState<number[]>([1, 1]);
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState("");
  const [playerWins, setPlayerWins] = useState<boolean>(false);
  const [showPopUp, setshowPopUp] = useState<boolean>(false);

  function rollTwoDices() {
    let duration = 2000; // 2 seconds
    let intervalTime = 200; // Interval time in milliseconds
    let endTime = Date.now() + duration;

    if (intrvl) {
      clearInterval(intrvl);
    }

    intrvl = setInterval(() => {
      let currentTime = Date.now();
      let remainingTime = endTime - currentTime;

      if (remainingTime <= 0) {
        clearInterval(intrvl);

        // Generate final random numbers for two dice between 1 and 6
        let finalRandomNum1 = Math.floor(Math.random() * faces) + 1;
        let finalRandomNum2 = Math.floor(Math.random() * faces) + 1;

        setDiceFaces([finalRandomNum1, finalRandomNum2]);

        // Check if the sum of dice faces equals user input
        const sumOfDice = finalRandomNum1 + finalRandomNum2;
        if (sumOfDice === parseInt(inputValue, 10)) {
          setPlayerWins(true);
          setTimeout(() => {
            setshowPopUp(true);
          }, 2000);
        } else {
          setPlayerWins(false);
          setTimeout(() => {
            setshowPopUp(true);
          }, 2000);
        }
      } else {
        // Generate random numbers for two dice between 1 and 6 and update state
        let randomNum1 = Math.floor(Math.random() * faces) + 1;
        let randomNum2 = Math.floor(Math.random() * faces) + 1;
        setBtnDisabled(true);
        setDiceFaces([randomNum1, randomNum2]);
      }
    }, intervalTime);
  }

  return (
    <div className="element flex flex-col  text-center justify-center  items-center  gap-y-10 md:gap-y-16 py-10">
      <div>
        <a href={REACT_APP_LOGO_URL} target="_blank" rel="noopener noreferrer">
          <img src={logo} className="h-10 cursor-pointer" alt="workplay-logo" />
        </a>
      </div>
      <DiceInput
        onRoll={rollTwoDices}
        inputValue={inputValue}
        setInputValue={setInputValue}
        disable={btnDisabled}
      ></DiceInput>
      <div className="flex  flex-col md:flex-row gap-y-16 md:gap-x-16">
        <Dice diceFace={diceFaces[0]}></Dice>
        <Dice diceFace={diceFaces[1]}></Dice>
      </div>
      {showPopUp && <PopUp state={playerWins}></PopUp>}
    </div>
  );
};

export default Game;
