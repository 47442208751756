import { Link } from "react-router-dom";
import logo from "../assets/workplay-logo-with-shadow.png";
const { REACT_APP_LOGO_URL } = process.env;

interface HomeProps {}

const Home: React.FC<HomeProps> = ({}) => {
  return (
    <div className="element flex flex-col items-center text-center gap-y-8 py-10 px-10">
      <a href={REACT_APP_LOGO_URL} target="_blank" rel="noopener noreferrer">
        <img src={logo} className="h-10 cursor-pointer" alt="Workplay-logo" />
      </a>
      <h1 className="text-black  text-2xl lg:text-3xl font-extrabold">
        Welcome to Roll the Dice game
      </h1>
      <div className=" text-sm  lg:text-xl text-start text-black font-semibold border-2 border-black leading-6  p-6 rounded-lg bg-blue-300  h-auto   w-auto ">
        <h1 className="text-xl lg:text-2xl font-bold mb-2">*Rules</h1>
        <ol>
          <li className="mb-1">1. Choose a number between 2 and 12.</li>
          <li className="mb-1">2. Roll the 2 dice.</li>
          <li className="mb-1">
            3. If your chosen number matches the dice outcome, you win!
          </li>
        </ol>
      </div>
      <Link to={"game"}>
        {" "}
        <button className=" button rounded-full  p-4 font-semibold text-black">
          START
        </button>
      </Link>
    </div>
  );
};

export default Home;
